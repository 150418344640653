<template>
  <b-row>
    <b-col sm="12" class="">
      <iq-card body-class="chat-page p-0">
        <template v-slot:body>
          <div class="chat-data-block">
            <b-row>
              <b-col lg="3" class="chat-data-left scroller">
                <div class="chat-search pt-3 pl-3">
                  <ToggleButton toggleShow="#user-detail-popup">
                    <template v-slot:media>
                      <img
                        v-if="user.image"
                        :src="user.image"
                        alt="chat-user"
                        class="avatar-60"
                      />
                      <img
                        v-else
                        class="avatar-60"
                        src="../../../assets/images/user-icon.svg"
                        alt="chat-user"
                      />
                    </template>
                    <template v-slot:body>
                      <h5 class="mb-0">{{ user.name }}</h5>
                      <p class="m-0">{{ user.role }}</p>
                    </template>
                  </ToggleButton>
                  <ToggleContent id="user-detail-popup" body-class="text-left">
                    <template v-slot:media>
                      <img
                        v-if="user.image"
                        :src="user.image"
                        alt="avatar"
                        width="200"
                      />
                      <img
                        v-else
                        width="200"
                        src="../../../assets/images/user-icon.svg"
                        alt="avatar"
                      />
                    </template>
                    <template v-slot:title>
                      <div class="user-name mt-4">
                        <h4>{{ user.name }}</h4>
                      </div>
                      <div class="user-desc">
                        <p>{{ user.role }}</p>
                      </div>
                    </template>
                    <template v-slot:body>
                      <h5 class="mt-4 mb-4">About</h5>
                      <p>{{ user.about }}</p>
                      <h5 class="mt-3 mb-3">Status</h5>
                      <ul class="user-status p-0">
                        <li class="mb-1">
                          <i
                            class="ri-checkbox-blank-circle-fill text-success pr-1"
                          ></i
                          ><span>Online</span>
                        </li>
                        <li class="mb-1">
                          <i
                            class="ri-checkbox-blank-circle-fill text-warning pr-1"
                          ></i
                          ><span>Away</span>
                        </li>
                        <li class="mb-1">
                          <i
                            class="ri-checkbox-blank-circle-fill text-danger pr-1"
                          ></i
                          ><span>Do Not Disturb</span>
                        </li>
                        <li class="mb-1">
                          <i
                            class="ri-checkbox-blank-circle-fill text-light pr-1"
                          ></i
                          ><span>Offline</span>
                        </li>
                      </ul>
                    </template>
                  </ToggleContent>
                  <div class="chat-searchbar mt-4">
                    <div class="form-group chat-search-data m-0">
                      <input
                        type="text"
                        class="form-control round"
                        id="chat-search"
                        placeholder="Search"
                        v-model="search"
                      />
                      <i class="ri-search-line" />
                    </div>
                  </div>
                </div>
                <div class="chat-sidebar-channel scroller mt-4 pl-3">
                  <tab-nav
                    :pills="true"
                    :vertical="true"
                    class="iq-chat-ui"
                    id="chat-list-data"
                  >
                    <template v-for="(item, index) in publicList">
                      <tab-nav-items
                        :key="index"
                        :id="`v-pills-${item.id}`"
                        href="#v-pills-home"
                        :ariaControls="`v-pills-${item.id}`"
                        role="tab"
                        v-on:click.native="getChat(item)"
                      >
                        <template v-slot:title>
                          <ChatItem :item="item" />
                        </template>
                      </tab-nav-items>
                      <!-- <a href="#v-pills-home" :ariaControls="`v-pills-${item.id}`"  @click="getChat(item)" :id="`v-pills-${item.id}`" :key="index+23"  role="tab"><ChatItem :item="item" /></a> -->
                    </template>
                  </tab-nav>
                </div>
              </b-col>

              <div
                class="col-lg-9 chat-data p-0 chat-data-right"
                :style="
                  `background: url(${require('../../../assets/images/page-img/100.jpg')}) no-repeat scroll center center;background-size: cover;`
                "
              >
                <tab-content id="v-pills-tabContent">
                  <tab-content-item
                    :active="true"
                    id="v-pills-default"
                    aria-labelled-by="v-pills-default"
                  >
                    <template>
                      <ToggleButton
                        :mini-toggle="true"
                        :close-button="false"
                        toggleShow="#chat-user-detail-popup"
                        mediaClass="chat-user-profile"
                      >
                        <template v-slot:media> </template>
                        <template v-slot:body> </template>
                      </ToggleButton>
                      <div class="chat-start">
                        <div class="chat-start">
                          <span class="iq-start-icon text-primary"
                            ><i class="ri-message-3-line"></i
                          ></span>
                          <button id="chat-start" class="btn bg-white mt-3">
                            Start Conversation!
                          </button>
                        </div>
                      </div>
                    </template>
                  </tab-content-item>
                  <tab-content-item
                    id="v-pills-home"
                    aria-labelled-by="v-pills-default"
                  >
                    <template>
                      <div class="chat-head">
                        <header
                          class="d-flex justify-content-between align-items-center bg-white pt-3 pr-3 pb-3"
                        >
                          <ToggleButton
                            :mini-toggle="true"
                            :close-button="false"
                            toggleShow="#chat-user-detail-popup"
                            mediaClass="chat-user-profile"
                          >
                            <template v-slot:media>
                              <!-- <img :src="checkUser(5, 'image')" alt="avatar" class="avatar-50 "> -->
                              <img
                                v-if="clickUser.image"
                                :src="clickUser.image"
                                alt="avatar"
                                class="avatar-50 "
                              />
                              <img
                                v-else
                                class="avatar-50"
                                src="../../../assets/images/user-icon.svg"
                                alt="avatar"
                              />
                              <span class="avatar-status"
                                ><i
                                  class="ri-checkbox-blank-circle-fill text-success"
                              /></span>
                            </template>
                            <template v-slot:body>
                              <!-- <h5 class="mb-0">{{ checkUser(5, 'name') }}</h5> -->
                              <h5 class="mb-0">{{ clickUser.name }}</h5>
                            </template>
                          </ToggleButton>
                          <ToggleContent
                            id="chat-user-detail-popup"
                            bodyClass="chatuser-detail"
                            center
                          >
                            <template v-slot:media>
                              <img
                                v-if="clickUser.image"
                                :src="clickUser.image"
                                alt="avatar"
                                width="300"
                              />
                              <img
                                v-else
                                src="../../../assets/images/user-icon.svg"
                                width="300"
                                alt="avatar"
                              />
                            </template>
                            <template v-slot:title>
                              <div class="user-name mt-4">
                                <h4>{{ clickUser.name }}</h4>
                              </div>
                              <div class="user-desc">
                                <p>{{ clickUser.country }}</p>
                              </div>
                            </template>
                            <template v-slot:body>
                              <div class="row">
                                <div class="col-6 col-md-6 title">
                                  Nik Name:
                                </div>
                                <div class="col-6 col-md-6 text-right">
                                  {{ clickUser.nickname }}
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col-6 col-md-6 title">Tel:</div>
                                <div class="col-6 col-md-6 text-right">
                                  {{ clickUser.tel }}
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col-6 col-md-6 title">
                                  Date Of Birth:
                                </div>
                                <div class="col-6 col-md-6 text-right">
                                  {{ clickUser.dob }}
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col-6 col-md-6 title">Gender:</div>
                                <div class="col-6 col-md-6 text-right">
                                  {{ clickUser.gender }}
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col-6 col-md-6 title">
                                  Language:
                                </div>
                                <div class="col-6 col-md-6 text-right">
                                  {{ clickUser.lang }}
                                </div>
                              </div>
                            </template>
                          </ToggleContent>
                          <!-- <div class="chat-header-icons d-flex">
                              <a class="iq-bg-primary iq-waves-effect mr-1 chat-icon-delete" @click="deleteChat(clickUser.id)"><i class="ri-delete-bin-line  mr-0" /></a>
                            </div> -->
                        </header>
                      </div>
                      <div class="chat-content scroller">
                        <p
                          v-if="allLoadError"
                          direction="top"
                          class="text-center mx-1"
                        >
                          {{ allLoadError }}
                        </p>
                        <span v-else
                          ><infinite-loading
                            @infinite="infiniteHandler"
                            :identifier="infiniteId"
                          ></infinite-loading
                        ></span>
                       
                        <template  v-for="(item, index) in chat">
                          <div class="chat" :key="index" v-if="!item.me">
                            <div class="chat-user">
                              <a class="avatar m-0">
                                <img
                                  v-if="user.image"
                                  :src="user.image"
                                  alt="avatar"
                                  class="avatar-35"
                                />
                                <img
                                  v-else
                                  class="avatar-35"
                                  src="../../../assets/images/user-icon.svg"
                                  alt="avatar"
                                />
                              </a>
                              <span class="chat-time mt-1">{{
                                item.time
                              }}</span>
                            </div>
                            <div class="chat-detail">
                              <div class="chat-message">
                                <p>{{ item.text }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="chat chat-left" :key="index" v-else>
                            <div class="chat-user">
                              <a class="avatar m-0">
                                <img
                                  v-if="clickUser.image"
                                  :src="clickUser.image"
                                  alt="avatar"
                                  class="avatar-35 "
                                />
                                <img
                                  v-else
                                  class="avatar-35"
                                  src="../../../assets/images/user-icon.svg"
                                  alt="avatar"
                                />
                              </a>
                              <span class="chat-time mt-1">{{
                                item.time
                              }}</span>
                            </div>
                            <div class="chat-detail">
                              <div class="chat-message">
                                <p>{{ item.text }}</p>
                              </div>
                            </div>
                          </div>
                        </template>
                        
                      </div>
                      <div class="chat-footer p-3 bg-white">
                        <form
                          class="d-flex align-items-center"
                          @submit.prevent="handleMessage(clickUser.id)"
                        >
                          <!-- <div class="chat-attagement d-flex">
                              <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                              <a href="javascript:void(0)"><i class="fa fa-paperclip pr-3" aria-hidden="true"></i></a>
                            </div> -->
                          <input
                            type="text"
                            v-model="messageData.message"
                            class="form-control mr-3"
                            placeholder="Type your message"
                          />
                          <button
                            type="submit"
                            class="btn btn-primary d-flex align-items-center p-2"
                          >
                            <i class="far fa-paper-plane"></i
                            ><span class="d-none d-lg-block ml-1">Send</span>
                          </button>
                        </form>
                      </div>
                    </template>
                  </tab-content-item>
                </tab-content>
              </div>
            </b-row>
          </div>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
import { socialvue } from "../../../config/pluginInit";
import ChatItem from "../../../components/Chat/ChatItem";
import ToggleButton from "../../../components/Chat/ToggleButton";
import ToggleContent from "../../../components/Chat/ToggleContent";
import { Users, MessagesUser1 } from "../../../FackApi/api/chat";
import User from "../../../Model/User";
import axios from "../../../components/axios";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "Index",
  components: { ChatItem, ToggleButton, ToggleContent, InfiniteLoading },
  mounted() {
    var userData = JSON.parse(localStorage.getItem("user"));
    this.user.name = userData.name;
    this.user.image = userData.avatar;
    var udata = JSON.parse(userData.user_data);
    this.user.role = udata.designation;
    this.user.about = udata.about_me;
    axios.defaults.headers["Authorization"] =
      "Bearer " + localStorage.getItem("token");

    socialvue.index();
    var parent = this;
    var config = {
      method: "get",
      url: "chatusers",
    };

    axios(config)
      .then(function(response) {
        parent.usersList = response.data.chatusers;
      })
      .then(() => {
        var firstElementOfChat = document.getElementsByClassName("nav-link");
        firstElementOfChat[0].click();
        setTimeout(() => {
          firstElementOfChat[0].click();
        }, 1000);
      })
      .catch(function(error) {
        console.log(error);
      });

    setInterval(function() {
      parent.callChatApi();
    }, 5000);
  },
  computed: {
    filteredList() {
      return this.usersList.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    publicList() {
      return this.usersList.filter((item) => {
        return !item.isPrivate
          ? item.name.toLowerCase().includes(this.search.toLowerCase())
          : false;
      });
    },
    privateList() {
      return this.usersList.filter((item) => {
        return item.isPrivate
          ? item.name.toLowerCase().includes(this.search.toLowerCase())
          : false;
      });
    },
  },

  data() {
    return {
      search: "",
      user: {
        id: 1,
        name: "",
        role: "",
        image: "",
        about: "",
        isActive: true,
      },
      usersList: [],
      chat: [],
      clickUser: {
        name: "",
        image: "",
        id: "",
        country: "",
        nickname: "",
        tel: "",
        dob: "",
        gender: "",
        lang: "",
      },
      messageData: {
        to_user_id: "",
        message: "",
      },
      //get from localstorge
      loggedInUser: {
        // avatar:'http://wfdapi.hyathi.com/uploads/avatars/Sahil%20Shah-6.jpg'
      },
      lastmessageData: {
        timestamp: "",
        to_user_id: "",
      },
      page: 1,
      last_page: 1,
      user_id: "",
      allLoadError: "",
      infiniteId: +new Date(),
    };
  },
  methods: {
    deleteChat(to_user_id) {},
    getChat(user) {
      var parent = this;
      parent.clickUser.name = user.name;
      parent.clickUser.image = user.image;
      parent.clickUser.dob = user.dob;
      parent.clickUser.tel = user.phone;
      parent.clickUser.country = user.country;
      parent.clickUser.nickname = user.nickname;
      parent.clickUser.lang = user.lang;
      parent.clickUser.gender = user.gender;
      parent.clickUser.id = user.id;

      this.page = 1;
      this.chat = [];
      this.infiniteId += 1;
      this.allLoadError = "";
      parent.user_id = user.id;

      // var config = {
      //   method: "get",
      //   url: `chats/${user.id}`,
      // };
      // parent.user_id = user.id

      //  axios(config)
      // .then(function(response) {
      //   // var res = JSON.parse(response)
      //    console.log(response.data);
      //   console.log(response.data.chats);

      //   // console.log(response.data.posts);
      //   // console.log(parent.SocialPost);
      // parent.chat = response.data.chats.reverse()
      // parent.clickUser.name = user.name
      // parent.clickUser.image = user.image
      // parent.clickUser.dob = user.dob
      // parent.clickUser.tel = user.phone
      // parent.clickUser.country = user.country
      // parent.clickUser.nickname = user.nickname
      // parent.clickUser.lang = user.lang
      // parent.clickUser.gender = user.gender
      // parent.clickUser.id = user.id
      //   // console.log(parent.chat);
      //   // parent.scrollToEnd();
      // })
      // .catch(function(error) {
      //   console.log(error);
      // });
    },

    checkUser(item, type) {
      let user = this.usersList.find((user) => user.id === item);
      let final;
      if (user !== undefined) {
        switch (type) {
          case "name":
            final = user.name;
            break;
          case "image":
            final = user.image;
            break;
          case "role":
            final = user.role;
            break;
        }
        return final;
      }
      return require("../../../assets/images/user/user-05.jpg");
    },
    handleMessage(to_user_id) {
      var parent = this;
      parent.messageData.to_user_id = to_user_id;
      var config = {
        method: "post",
        url: "sendmessage",
        data: parent.messageData,
      };

      axios(config)
        .then(function(response) {

          // parent.chat.push(response.data.chat)
          parent.chat.push(response.data.chat);
          parent.lastmessageData.timestamp = response.data.chat.timestamp;
          parent.lastmessageData.to_user_id = response.data.chat.to_user_id;
          parent.messageData.message = "";
          // parent.callChatApi();
          // console.log(response.data.posts);
          // console.log(parent.SocialPost);
          // parent.chat = response.data.chats
          // parent.clickUser.name = user.name
          // parent.clickUser.image = user.image
          // parent.clickUser.id = user.id
          // console.log(parent.chat);

          // parent.messageData.message = ''
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    callChatApi() {
      

      {
        var parent = this;
        if(parent.user_id){
           var config = {
            method: "get",
            url: `chats/${parent.user_id}`,
            params: {
              page: 1,
            },
          };


           axios(config).then((response) => {
          var lastStoredMessage = this.chat[this.chat.length - 1];
          var lastMessageFromApi = response.data.chats
            .reverse()
            .slice(-1)
            .pop();
          if(lastStoredMessage){
             if (lastStoredMessage.text == lastMessageFromApi.text) {
            } else {
              this.chat.push(lastMessageFromApi);
            }
          }
         
        });
        }
       

       
      }
    },
    async infiniteHandler($state) {
      var parent = this;
      if(parent.user_id){
          var config = {
            method: "get",
            url: `chats/${parent.user_id}`,
            params: {
              page: parent.page,
            },
          };

           if (parent.page <= parent.last_page) {
            await axios(config).then((response) => {
              parent.last_page = response.data.chtpaginate.last_page;
              if (parent.page <= response.data.chtpaginate.last_page) {
                parent.page += 1;
                parent.chat.unshift(...response.data.chats.reverse());
                $state.loaded();
              } else {
                $state.complete();
              }
            });
          } else {
            this.allLoadError = "(: Not Found ";
          }
      }
      

     
    },
  },
};
</script>
<style>
.remove-toggle::after {
  content: unset;
}
.scroller {
  overflow-y: initial !important;
}
[dir="ltr"][mode="light"] #user-detail-popup {
  width: 240px !important;
}
.content-page {
  min-height: auto !important;
}
</style>
